import React, { Fragment, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Grid from '@mui/material/Grid';
import * as config from '../../../config/site';

// appsolut base components
import ApiDataService from '../../api/NewsletterDataService';
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledButton from '../../components/controls/StyledButton';
import FormInput from '../../components/controls/FormInput';
import FormMessage from './FormMessage';
import ToastMessage from './ToastMessage';
//import { DisplayFormikState } from '../../utils/formik';

function NewsletterConfirmForm({ params }) {
  // parameter management
  const initialFormMessage = 'Bestätigung fehlgeschlagen. Bitte prüfen Sie Ihre Eingabe.';
  const initialSuccessMessage = 'Ihre Bestätigung wurde erfolgreich durchgeführt';
  const initialErrorMessage = 'Ein Fehler ist aufgetreten, please retry.';

  const fieldEmailLbl = 'E-Mail';
  const fieldEmail = useMemo(() => params.email, []);
  const fieldContactId = useMemo(() => params.contactId, []);

  const initSalutation = params && params.salutation ? params.salutation : '';
  const initFirstname = params && params.firstname ? params.firstname : '';
  const initSurname = params && params.surname ? params.surname : '';
  const initInfo = params && params.info ? params.info : '';

  // state
  const [showFormMessage, setShowFormMessage] = useState(false);
  const [formMessage] = useState(initialFormMessage);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage] = useState(initialSuccessMessage);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(initialErrorMessage);
  const [loading, setLoading] = useState(false);

  // event handler
  const toggleSuccess = () => setShowSuccess(!showSuccess);
  const toggleError = () => setShowError(!showError);
  const sendFormDataToServer = async (payload) => {
    setLoading(true);
    try {
      const flowResult = await ApiDataService.sendNewsletterConfirm(payload);
      setLoading(false);
      //setShowSuccess(true);
      setShowFormMessage(false);

      // navigate to caller page
      const navlink = config.pages.thankyou.newsletterConfirm;
      navigate(navlink);
    } catch (error) {
      setLoading(false);
      setShowFormMessage(true);
      setShowSuccess(false);
    }
  };

  // data management
  const formInitialValues = {
    salutation: initSalutation,
    firstname: initFirstname,
    surname: initSurname,
    info: initInfo,
  };
  const formValidation = Yup.object({
    salutation: Yup.string().max(50, 'Max. 50 Zeichen'),
    firstname: Yup.string().max(50, 'Max. 50 Zeichen').required('Pflichtfeld'),
    surname: Yup.string().max(50, 'Max. 50 Zeichen').required('Pflichtfeld'),
    info: Yup.string(),
  });

  // rendering
  return (
    <Fragment>
      <Formik
        initialValues={formInitialValues}
        validationSchema={formValidation}
        onSubmit={(values, actions) => {
          try {
            const payload = {
              ...values,
              email: fieldEmail,
              contactId: fieldContactId,
            };
            sendFormDataToServer(payload);
            actions.resetForm();
          } catch (error) {
            console.log(error);
            setErrorMessage(error.message);
            setShowError(true);
          } finally {
            actions.setSubmitting(false);
          }
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <StyledBox pt={0.5} pb={3} px={3}>
              <Grid container>
                <Grid item xs={12} pr={1} mb={3}>
                  <StyledBox pb={{ xs: 2, md: 3 }}>
                    <StyledTypography component="div" variant="caption">
                      {fieldEmailLbl}
                    </StyledTypography>
                    <StyledTypography variant="h3">{fieldEmail}</StyledTypography>
                  </StyledBox>
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="salutation"
                    label="Titel"
                    placeholder="Anrede oder Titel"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="firstname"
                    label="Vorname"
                    placeholder="Vorname"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="surname"
                    label="Zuname"
                    placeholder="Familienname"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    required
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} pr={1} mb={3}>
                  <FormInput
                    name="info"
                    label="Nachricht"
                    placeholder="Ihre Nachricht..."
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    multiline
                    rows={6}
                  />
                </Grid>
              </Grid>
              <FormMessage message={formMessage} open={showFormMessage} />
              <Grid
                container
                item
                xs={12}
                md={6}
                justifyContent="flex-end"
                textAlign="right"
                ml="auto"
              >
                <StyledButton
                  type="submit"
                  disabled={formik.isSubmitting || loading}
                  variant="gradient"
                  color="info"
                >
                  Bestätigen
                </StyledButton>
              </Grid>
            </StyledBox>
            {/* <DisplayFormikState {...formik} /> */}
          </form>
        )}
      </Formik>
      <ToastMessage
        message={errorMessage}
        open={showError}
        severity={'error'}
        onClose={toggleError}
      />
      <ToastMessage message={successMessage} open={showSuccess} onClose={toggleSuccess} />
    </Fragment>
  );
}

NewsletterConfirmForm.propTypes = {
  params: PropTypes.instanceOf(Object),
};

export default NewsletterConfirmForm;
